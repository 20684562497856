import client from "./requestclient";

const setVendorProfile = {
  vendorProfileStepOne: async (stepOnePayload) => {
    return await client({
      URL_PATH: `setup-vendor-step-one`,
      method: "POST",
      body: stepOnePayload,
    });
  },

  vendorProfileStepTwo: async (stepTwoPayload) => {
    return await client({
      URL_PATH: `setup-vendor-step-two`,
      method: "POST",
      body: stepTwoPayload,
    });
  },

  vendorProfileStepThree: async (stepThreePayload) => {
    return await client({
      URL_PATH: `setup-vendor-step-three`,
      method: "POST",
      body: stepThreePayload,
    });
  },
};

export default setVendorProfile;
