<template>
  <div class="business-form-container">
    <div class="step-container" v-if="step === 1">
      <div class="form-header">
        <button class="back-button" v-if="step > 1" @click="prevStep">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5025 16.5984L7.06914 11.1651C6.42747 10.5234 6.42747 9.47344 7.06914 8.83177L12.5025 3.39844"
              stroke="#555C74"
              stroke-width="1.6"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Back
        </button>
        <p class="step-text">Step 1 of 3</p>
        <h2 class="form-title">Business info</h2>
        <p class="form-description">
          Let's start with the essentials! Tell us a bit about your business.
        </p>
      </div>
      <form class="form-content">
        <div class="form-group">
          <label for="business-name" class="form-label"
            >Business name <span class="required">*</span></label
          >
          <input
            type="text"
            id="business-name"
            class="form-input"
            v-model="multiFormData.businessName"
            placeholder="What's your business called?"
          />
        </div>
        <div class="form-group">
          <label for="business-address" class="form-label"
            >Business address <span class="required">*</span></label
          >
          <input
            type="text"
            id="business-address"
            class="form-input"
            v-model="multiFormData.businessAddress"
            placeholder="Where are you located?"
          />
        </div>

        <div class="form-group">
          <label for="about-business" class="form-label"
            >About your business</label
          >
          <textarea
            id="about-business"
            class="form-textarea"
            v-model="multiFormData.aboutDescription"
            placeholder="Give a brief intro about what you do"
            maxlength="5000"
          ></textarea>
          <p class="char-count">5000 characters</p>
        </div>
        <div class="next-btn-container">
          <button class="next-button" @click.prevent="nextStep()">Next</button>
        </div>
      </form>
    </div>

    <!-- STEP 2 -->
    <div class="step-container" v-if="step === 2">
      <div class="form-header">
        <button class="back-button" v-if="step > 1" @click="prevStep">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5025 16.5984L7.06914 11.1651C6.42747 10.5234 6.42747 9.47344 7.06914 8.83177L12.5025 3.39844"
              stroke="#555C74"
              stroke-width="1.6"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Back
        </button>
        <p class="step-text">Step 2 of 3</p>
        <h2 class="form-title">Service details</h2>
        <p class="form-description">
          Help potential customers understand what you do best
        </p>
      </div>
      <form class="form-content">
        <div class="form-group">
          <label for="servicesProvided" class="form-label"
            >Services provided <span class="required">*</span></label
          >
          <select
            v-model="selectedService"
            id="services"
            class="dropdown-input"
          >
            <option value="" disabled selected>Select services offered</option>
            <option
              v-for="service in services"
              :key="service.id"
              :value="service.name"
            >
              {{ service.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="minimumRate" class="form-label"
            >Minimum rate <span class="required">*</span></label
          >
          <input
            type="text"
            id="minimumRate"
            class="form-input"
            v-model="multiFormData.minimumRate"
            placeholder="₦1000"
          />
          <label for="startingPrice" class="form-label"
            >Starting price for your service</label
          >
        </div>

        <div class="form-group">
          <label
            >What days are you available? <span class="required">*</span></label
          >
        </div>
        <div v-for="(day, index) in days" :key="index" class="availability-row">
          <div class="day-toggle">
            <label class="switch">
              <input
                type="checkbox"
                v-model="day.enabled"
                @change="toggleDay(index)"
              />
              <span class="slider"></span>
            </label>
            <span class="day-name">{{ day.name }}</span>
          </div>

          <div class="time-slots">
            <template v-if="day.enabled && day.timeSlots.length > 0">
              <div
                v-for="(slot, slotIndex) in day.timeSlots"
                :key="slotIndex"
                class="time-slot"
              >
                <select v-model="slot.start">
                  <option v-for="time in times" :value="time" :key="time">
                    {{ time }}
                  </option>
                </select>
                -
                <select v-model="slot.end">
                  <option v-for="time in times" :value="time" :key="time">
                    {{ time }}
                  </option>
                </select>
                <button
                  class="remove-slot"
                  @click.prevent="removeSlot(index, slotIndex)"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.11523 9.88661L9.88857 6.11328"
                      stroke="#555C74"
                      stroke-width="0.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.88857 9.88661L6.11523 6.11328"
                      stroke="#555C74"
                      stroke-width="0.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.00065 14.6654H10.0007C13.334 14.6654 14.6673 13.332 14.6673 9.9987V5.9987C14.6673 2.66536 13.334 1.33203 10.0007 1.33203H6.00065C2.66732 1.33203 1.33398 2.66536 1.33398 5.9987V9.9987C1.33398 13.332 2.66732 14.6654 6.00065 14.6654Z"
                      stroke="#555C74"
                      stroke-width="0.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </template>
            <span v-else class="unavailable-text">Unavailable</span>
          </div>

          <button class="add-slot" @click.prevent="addSlot(index)">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 12H18"
                stroke="#2B3352"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 18V6"
                stroke="#2B3352"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div class="next-btn-container" style="margin-top: 20px">
          <button class="next-button" @click.prevent="handleStepTwo()">
            Next
          </button>
        </div>
      </form>
    </div>

    <!-- STEP 3 -->
    <div v-if="step === 3" class="step-container">
      <div class="form-header">
        <button class="back-button" v-if="step > 1" @click="prevStep">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5025 16.5984L7.06914 11.1651C6.42747 10.5234 6.42747 9.47344 7.06914 8.83177L12.5025 3.39844"
              stroke="#555C74"
              stroke-width="1.6"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Back
        </button>
        <p class="step-text">Step 3 of 3</p>
        <h2 class="form-title">Additional business details</h2>
        <p class="form-description">
          This step is optional, but adding more details can help attract more
          customers.
        </p>
      </div>

      <!-- Business Registration (Default Open) -->
      <div class="accordion-container">
        <div class="accordion-header" @click="toggleDropdown('registration')">
          <div>
            <span>Business registration</span>
            <p
              v-show="activeDropdown === 'registration'"
              class="accordion-subtitle"
            >
              If your business is registered, provide some details
            </p>
          </div>
          <span
            v-html="activeDropdown === 'registration' ? '' : dropdownIcon"
          ></span>
        </div>
        <div v-if="activeDropdown === 'registration'" class="accordion-content">
          <label>Business registration name</label>
          <input
            v-model="formData.registrationName"
            placeholder="Registered name of the business"
            class="input-field"
          />

          <label>CAC number</label>
          <input
            v-model="formData.cacNumber"
            placeholder="CAC number"
            class="input-field"
          />
        </div>
      </div>

      <!-- Certification Details -->
      <div class="accordion-container">
        <div class="accordion-header" @click="toggleDropdown('certification')">
          <div>
            <span>Certification details</span>
            <p
              v-show="activeDropdown === 'certification'"
              class="accordion-subtitle"
            >
              Have any professional certifications? Let potential clients know
            </p>
          </div>
          <span
            v-html="activeDropdown === 'certification' ? '' : dropdownIcon"
          ></span>
        </div>
        <div
          v-if="activeDropdown === 'certification'"
          class="accordion-content"
        >
          <label>Certification name</label>
          <input
            v-model="formData.certificationName"
            placeholder="Name of the certificate"
            class="input-field"
          />

          <label>Issuing organization</label>
          <input
            v-model="formData.issuingOrg"
            placeholder="Who issued the certification?"
            class="input-field"
          />

          <label>Date obtained</label>

          <div class="date-picker-wrapper">
            <!-- Input field -->
            <input
              type="text"
              @focus="showDatePicker = true"
              readonly
              v-model="formData.certificationDate"
              class="date-input"
            />

            <!-- Calendar Icon -->
            <span class="calendar-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 2v2M16 2v2M3 8h18M5 4h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>

            <!-- Hidden Date Picker -->
            <input
              type="date"
              v-model="formData.certificationDate"
              ref="datePicker"
              @input="updateDate"
              class="hidden-date-input"
            />
          </div>
        </div>
      </div>

      <!-- Work Samples -->
      <div class="accordion-container">
        <div class="accordion-header" @click="toggleDropdown('workSamples')">
          <div>
            <span>Work samples</span>
            <p
              v-show="activeDropdown === 'workSamples'"
              class="accordion-subtitle"
            >
              Showcase some of your best work
            </p>
          </div>

          <span
            v-html="activeDropdown === 'workSamples' ? '' : dropdownIcon"
          ></span>
        </div>

        <div v-if="activeDropdown === 'workSamples'" class="accordion-content">
          <div class="tips-box">
            <span class="tips-icon"
              ><svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3663_137326)">
                  <path
                    d="M7.33333 4V6H8.66667V4H7.33333ZM2.8151 5.8724L1.8724 6.8151L3.28646 8.22917L4.22917 7.28646L2.8151 5.8724ZM13.1849 5.8724L11.7708 7.28646L12.7135 8.22917L14.1276 6.8151L13.1849 5.8724ZM8 7.33333C5.43044 7.33333 3.33333 9.43044 3.33333 12C3.33333 13.8452 4.43741 15.4015 6 16.1562V18C6 18.7287 6.60463 19.3333 7.33333 19.3333H8.66667C9.39538 19.3333 10 18.7287 10 18V16.1562C11.5626 15.4015 12.6667 13.8452 12.6667 12C12.6667 9.43044 10.5696 7.33333 8 7.33333ZM8 8.66667C9.84911 8.66667 11.3333 10.1509 11.3333 12C11.3333 13.4562 10.4036 14.6819 9.11068 15.1393L8.66667 15.2956V18H7.33333V15.2956L6.88932 15.1393C5.59644 14.6819 4.66667 13.4562 4.66667 12C4.66667 10.1509 6.15089 8.66667 8 8.66667ZM0 11.3333V12.6667H2V11.3333H0ZM14 11.3333V12.6667H16V11.3333H14ZM3.28646 15.7708L1.8724 17.1849L2.8151 18.1276L4.22917 16.7135L3.28646 15.7708ZM12.7135 15.7708L11.7708 16.7135L13.1849 18.1276L14.1276 17.1849L12.7135 15.7708Z"
                    fill="#B54708"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3663_137326">
                    <rect width="16" height="19.3333" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <p class="tips-text">
              To foster trust and showcase the quality of your work, add images
              of the 'before' and 'after' states of your work.
            </p>
          </div>

          <div class="work-samples-container">
            <div
              v-for="(sample, index) in workSamples"
              :key="index"
              class="work-sample"
            >
              <div class="work-sample-header">
                <h3>Sample {{ index + 1 }}</h3>
                <button class="edit-btn" @click="editSample(index)">
                  Edit
                </button>
              </div>

              <p class="work-summary">
                <strong>Work summary:</strong>
                <span> {{ sample.summary }}</span>
              </p>

              <div
                v-if="sample.images.length > 0"
                class="image-preview-section"
              >
                <div
                  v-for="(image, imgIndex) in sample.images"
                  :key="imgIndex"
                  class="image-preview"
                >
                  <img
                    :src="image"
                    alt="Work sample image"
                    class="uploaded-image"
                  />
                </div>
              </div>
            </div>

            <!-- Work Sample Input Fields -->
            <div v-if="showNewSampleForm">
              <label>Work summary</label>
              <input
                type="text"
                v-model="newSummary"
                placeholder="Enter work summary"
                class="input-field"
              />

              <label>Provide images</label>
              <div class="image-upload-section">
                <div class="image-upload-box" @click="triggerFileUpload">
                  <span class="plus-icon">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5 5V19"
                        stroke="#555C74"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.5 12H19.5"
                        stroke="#555C74"
                        stroke-width="1.5"
                      />
                    </svg>
                  </span>
                  <p>Add image</p>
                </div>
                <input
                  type="file"
                  ref="fileInput"
                  @change="handleImageUpload"
                  accept="image/*"
                  hidden
                />

                <div
                  v-for="(image, index) in newImages"
                  :key="index"
                  class="image-preview"
                >
                  <img
                    :src="image"
                    alt="Uploaded image"
                    class="uploaded-image"
                  />
                  <button class="delete-btn" @click="removeNewImage(index)">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="36"
                        height="36"
                        rx="8"
                        fill="white"
                        fill-opacity="0.5"
                      />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="35"
                        height="35"
                        rx="7.5"
                        stroke="white"
                        stroke-opacity="0.3"
                      />
                      <path
                        d="M25.5 12.9831C22.725 12.7081 19.9333 12.5664 17.15 12.5664C15.5 12.5664 13.85 12.6497 12.2 12.8164L10.5 12.9831"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.082 12.143L15.2654 11.0513C15.3987 10.2596 15.4987 9.66797 16.907 9.66797H19.0904C20.4987 9.66797 20.607 10.293 20.732 11.0596L20.9154 12.143"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M23.7057 15.6172L23.1641 24.0089C23.0724 25.3172 22.9974 26.3339 20.6724 26.3339H15.3224C12.9974 26.3339 12.9224 25.3172 12.8307 24.0089L12.2891 15.6172"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.6055 21.75H19.3805"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.918 18.418H20.0846"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <!-- Add Another Button -->
            <button class="add-another-btn" @click="addAnotherSample">
              Add another
            </button>
          </div>
        </div>
      </div>
      <div class="next-btn-container">
        <button class="next-button" @click.prevent="submitProfileDetails()">
          Complete setup
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import setVendorProfile from "../services/vendorprofile";
export default {
  data() {
    return {
      step: 1,
      activeDropdown: "registration", // This should be open by default
      showDatePicker: false,
      multiFormData: {
        businessName: "",
        businessAddress: "",
        aboutDescription: "",
        service: "",
        rate: "",
        registrationName: "",
        cacNumber: "",
        certificationName: "",
        issuingOrg: "",
        certificationDate: "",
        workSamples: null,
        workSummary: "",
        minimumRate: "",
        serviceID: null,
      },

      availability: [
        {
          day: "",
          start_time: "",
          end_date: "",
        },
      ],
      //   services: ["Plumbing", "Electrical Work", "Painting"],
      services: [
        { id: 1, name: "Plumbing" },
        { id: 2, name: "Electrical Work" },
        { id: 3, name: "Painting" },
      ],
      selectedService: "", // Stores the selected option from above
      workSamples: [], // Stores completed work samples
      newSummary: "",
      newImages: [],
      showNewSampleForm: true,
      days: [
        { name: "Monday", enabled: false, timeSlots: [] },
        { name: "Tuesday", enabled: false, timeSlots: [] },
        { name: "Wednesday", enabled: false, timeSlots: [] },
        { name: "Thursday", enabled: false, timeSlots: [] },
        { name: "Friday", enabled: false, timeSlots: [] },
      ],
      times: [
        "8:00 AM",
        "9:00 AM",
        "10:00 AM",
        "11:00 AM",
        "12:00 PM",
        "1:00 PM",
        "2:00 PM",
        "3:00 PM",
        "4:00 PM",
        "5:00 PM",
      ],
      dropdowns: {
        certification: false,
        workSamples: false,
      },
      dropdownIcon: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.9375 13.2788L10.2842 8.93208C10.7975 8.41875 10.7975 7.57875 10.2842 7.06542L5.9375 2.71875" stroke="#555C74" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
    };
  },

  methods: {
    nextStep() {
      const name = this.multiFormData.businessName.trim();
      const address = this.multiFormData.businessAddress.trim();
      const about = this.multiFormData.aboutDescription
        ? this.multiFormData.aboutDescription.trim()
        : "";

      if (!name || !address) {
        this.$toast.error("Please fill in all inputs");
      } else {
        const stepOneData = {
          businessName: name,
          businessAddress: address,
          aboutDescription: about,
        };

        this.handleStepOne(stepOneData);
        this.step++;
      }
    },

    prevStep() {
      if (this.step > 1) this.step--;
    },
    updateServiceID(selectedServiceName) {
      const service = this.services.find((s) => s.name === selectedServiceName);
      this.multiFormData.serviceID = service ? service.id : null;
    },

    toggleDay(index) {
      if (this.days[index].enabled) {
        if (this.days[index].timeSlots.length === 0) {
          this.days[index].timeSlots.push({ start: "8:00 AM", end: "5:00 PM" });
        }
      } else {
        this.days[index].timeSlots = [];
      }
    },
    addSlot(index) {
      this.days[index].timeSlots.push({ start: "8:00 AM", end: "5:00 PM" });
    },
    removeSlot(dayIndex, slotIndex) {
      this.days[dayIndex].timeSlots.splice(slotIndex, 1);
    },

    toggleDropdown(section) {
      this.activeDropdown = this.activeDropdown === section ? null : section;
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.newImages.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    removeNewImage(index) {
      this.newImages.splice(index, 1);
    },
    addAnotherSample() {
      if (this.newSummary && this.newImages.length > 0) {
        this.workSamples.push({
          summary: this.newSummary,
          images: [...this.newImages],
        });

        // Reset input fields
        this.newSummary = "";
        this.newImages = [];
      }
      this.showNewSampleForm = true;
    },
    editSample(index) {
      this.newSummary = this.workSamples[index].summary;
      this.newImages = [...this.workSamples[index].images];
      this.workSamples.splice(index, 1);
      this.showNewSampleForm = true;
    },

    handleStepOne() {
      console.log(this.stepOneData);
      const body = {
        business_name: this.multiFormData.businessName,
        business_address: this.multiFormData.businessAddress,
        about_business: this.multiFormData.aboutDescription,
      };

      setVendorProfile
        .vendorProfileStepOne(JSON.stringify(body))
        .then((res) => {
          if (res.success) {
            console.log("Data", res.data);
          } else {
            console.log("No data sent");
            this.$toast.error("Form not submitted");
          }
        })
        .catch((error) => {
          console.log("Error submitting data:", error);
          this.$toast.error("Error submitting");
        });
    },
    handleStepTwo() {
      const availability = this.days
        .filter((day) => day.enabled && day.timeSlots.length > 0) // Get only enabled days
        .map((day) => {
          return day.timeSlots.map((slot) => ({
            day: day.name,
            start_time: slot.start,
            end_date: slot.end,
          }));
        })
        .flat(); // Flatten the array

      const stepTwoPayload = {
        ...this.multiFormData,
        availability: availability,
      };

      //Firstly, convert service name to ID (if not already set)
      if (!this.multiFormData.serviceID) {
        this.updateServiceID(this.selectedService);
      }

      setVendorProfile
        .vendorProfileStepTwo(JSON.stringify(stepTwoPayload))
        .then((res) => {
          console.log("Res", res);
          this.step++;
        })
        .catch((err) => {
          console.log("Error submitting", err);
        });
    },
  },

  handleStepThree() {},

  computed: {
    displayDate() {
      return this.formData.certificationDate || "Select";
    },
  },
};
</script>

<style scoped>
/* EVERYTHING IN THIS COMPONENT SHOULD USE THE LATO FONT */
* {
  font-family: "Lato", sans-serif;
}

/* GENERAL*/
label {
  color: hsla(226, 15%, 39%, 1);
  font-size: 14px;
  font-weight: 400;
}

/* MULTI-STEP FORM */

.business-form-container {
  max-width: 630px;
  /* height: 596px; */
  margin: 40px auto;
  padding: 20px 40px;
  position: relative;
  background: #fff;
  border-radius: 8px;
}

.back-button {
  background: none;
  font-weight: 600;
  font-size: 14px;
  border: none;
  color: hsla(220, 8%, 35%, 1);
  cursor: pointer;
  margin-bottom: 10px;
}

.step-text {
  font-size: 14px;
  color: hsla(226, 15%, 39%, 1);
  font-weight: 500;
}

.form-title {
  font-size: 24px;
  font-weight: 600;
  color: hsla(228, 31%, 25%, 1);
}
.form-description {
  font-size: 16px;
  color: hsla(226, 15%, 39%, 1);
  font-weight: 400;
}
.form-group {
  margin-bottom: 15px;
}

.form-label {
  font-size: 14px;
  font-weight: 400;
  color: hsla(226, 15%, 39%, 1);
  display: block;
  margin-bottom: 10px;
}
.required {
  color: hsla(4, 74%, 49%, 1);
  font-size: 14px;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid hsla(216, 6%, 85%, 1);
  border-radius: 8px;
  font-size: 14px;
  color: hsla(226, 15%, 39%, 1);
}
.form-textarea {
  min-height: 150px;
  resize: none;
}
.form-input::placeholder,
.form-textarea::placeholder {
  color: (229, 10%, 70%, 1);
  font-size: 14px;
  font-weight: 500;
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
}

.char-count {
  font-size: 14px;
  color: hsla(226, 15%, 39%, 1);
  font-weight: 400;
  text-align: right;
}

.next-btn-container {
  display: flex;
  justify-content: flex-end;
}

.next-button {
  background-color: hsla(227, 100%, 46%, 1);
  color: #fff;
  border: none;
  border-radius: 8px;
  border: 2px solid hsla(210, 5%, 92%, 1);
  padding: 12px 18px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.next-button:disabled {
  background-color: hsla(0, 0%, 96%, 1);
  cursor: not-allowed;
  color: hsla(226, 15%, 39%, 0.6);
}

/*============  APPOINTMENTS/SCHEDULES ============= */
.appointments-container {
  background: #fff;
  margin-top: 24px;
  padding: 32px 24px;
  border-radius: 16px;
}

/* SELECT */
.dropdown-input {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid hsla(216, 6%, 85%, 1);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  color: hsla(229, 10%, 70%, 1);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2788 5.96484L8.93208 10.3115C8.41875 10.8248 7.57875 10.8248 7.06542 10.3115L2.71875 5.96484' stroke='%23555C74' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
}

.availability-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid hsla(210, 5%, 92%, 1);
}

.day-toggle {
  flex: 1;
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(0, 0%, 96%, 1);
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: hsla(227, 84%, 55%, 1);
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.day-name {
  font-size: 16px;
  font-weight: 500;
}

.time-slots {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}

.unavailable-text {
  color: hsla(216, 6%, 85%, 1);
}

.time-slot {
  display: flex;
  align-items: center;
  gap: 5px;
  background: hsla(0, 0%, 100%, 1);
  padding: 5px;
  border-radius: 8px;
}

select {
  border: 1px solid hsla(216, 6%, 85%, 1);
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
}

.remove-slot {
  background: none;
  border: none;
  color: hsla(226, 15%, 39%, 1);
  cursor: pointer;
}

.add-slot {
  flex: 0.5;
  background: none;
  border: none;
  color: hsla(228, 31%, 25%, 1);
  font-size: 18px;
  cursor: pointer;
}

.select-wrapper {
  position: relative;
  width: 110px;
}

.select-wrapper::after {
  content: "";
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-right: 2px solid hsla(210, 5%, 92%, 1);
  border-bottom: 2px solid hsla(210, 5%, 92%, 1);
  transform: translateY(-50%) rotate(45deg);
  pointer-events: none;
}

.select-wrapper select {
  width: 100%;
  padding-right: 30px;
  appearance: none;
}

/* General container */
.accordion-container {
  border: 1px solid hsla(210, 5%, 92%, 1);
  border-radius: 8px;
  margin-bottom: 10px;
  background: #fff;
}

/* Header styling */
.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  font-weight: 600;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  cursor: pointer;
  border: 1px solid hsla(210, 5%, 92%, 1);
  background: #fff;
}

.accordion-header span {
  color: hsla(228, 31%, 25%, 1);
  font-weight: 500;
  font-size: 18px;
}
/* Subtitle (Hidden when closed) */
.accordion-subtitle {
  font-size: 14px;
  font-weight: 400;

  color: hsla(226, 15%, 39%, 1);
  margin-top: 10px;
}

.accordion-content {
  padding: 12px;
  border-top: 1px solid hsla(210, 5%, 92%, 1);
  background: #fff;
}

.input-field {
  width: 100%;
  padding: 8px 14px;
  margin-bottom: 10px;
  margin-top: 5px;
  border: 1px solid hsla(216, 6%, 85%, 1);
  border-radius: 8px;
}

input:focus,
.dropdown-input:focus,
textarea:focus {
  outline: none;
}
@media (max-width: 768px) {
  .business-form-container {
    width: 100%;

    margin: 20px auto;
    padding: 20px 0;
  }

  .select-wrapper {
    width: 100%;
  }
  .unavailable-text {
    margin-left: 10px;
  }
}

.tips-box {
  background: hsla(45, 100%, 96%, 1);
  padding: 16px 10px;
  border-radius: 8px;
  display: flex;
  width: 98%;
  margin: 20px auto;
  gap: 15px;
}

.tips-text {
  font-size: 14px;
  color: hsla(226, 15%, 39%, 1);
  font-weight: 400;
}

/* Wrapper for input and icon */
.date-picker-wrapper {
  position: relative;
  width: 100%;
}

/* Input field */
.date-input {
  width: 100%;
  padding: 12px;
  padding-right: 40px;
  border: 1px solid hsla(210, 5%, 92%, 1);
  border-radius: 6px;
  font-size: 14px;
  color: #333;
  background: #fff;
  cursor: pointer;
}

/* Calendar icon */
.calendar-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: hsla(226, 15%, 39%, 1);
}

/* Hidden native date picker */
.hidden-date-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* WORK SAMPLS IMAGES */

.work-samples-container {
  padding: 20px;
  border: 1px solid hsla(210, 5%, 92%, 1);
  border-radius: 8px;
  background: #fff;
}

.work-sample {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid hsla(210, 5%, 92%, 1);
}

.work-sample-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: hsla(228, 31%, 25%, 1);
  font-weight: 600;
  font-size: 18px;
}

.edit-btn {
  color: hsla(227, 100%, 46%, 1);
  cursor: pointer;
  border: none;
  background: none;
  font-weight: 600;
  font-size: 14px;
}

.work-summary {
  font-size: 14px;
  color: hsla(228, 31%, 25%, 1);
  font-weight: 600;
}
.work-summary {
  color: hsla(226, 15%, 39%, 1);
  font-weight: 400;
}
.image-preview-section {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.image-preview {
  position: relative;
  width: 150px;
  height: 150px;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
}

.input-field {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid hsla(210, 5%, 92%, 1);
  border-radius: 6px;
}

.image-upload-section {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.image-upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border: 2px dashed hsla(210, 5%, 92%, 1);
  border-radius: 6px;
  cursor: pointer;
}

.plus-icon {
  font-size: 20px;
  font-weight: bold;
}

.add-another-btn {
  margin-top: 20px;
  padding: 8px 12px;
  background: hsla(0, 0%, 100%, 1);
  color: hsla(221, 11%, 29%, 1);
  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  border: 1px solid hsla(216, 6%, 85%, 1);
  cursor: pointer;
}
</style>
